/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-28",
    versionChannel: "nightly",
    buildDate: "2024-08-28T00:05:29.300Z",
    commitHash: "99d7cce82e7bfeb9127c60451783cdfe4d4acc9c",
};
